@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	color: white;
	background-color: black;
}

* {
	box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
	font-size: 36px;
}

.module {
	max-width: 1400px;
	width: 100%;
	position: relative;
	transform: translateY(150px);
}

.module.in-view {
	transform: translateY(0);
	transition: transform .5s ease-out;
}

.module.no-transition {
	transform: unset;
	transition: none;
}

@media (max-width: 768px) {
	h2 {
		font-size: 22px;
	}

	body {
		font-size: 14px;
	}

	.module {
		top: 50px;
	}
}

img {
	width: 100%;
}

figure {
	margin: 0;
}

.visible-mobile {
	display: none;
}

@media (max-width: 768px) {
	.hidden-mobile {
		display: none;
	}

	.visible-mobile {
		display: block;
	}

	.visible-mobile-flex {
		display: flex;
	}
}


a{
	text-decoration: none;
	color: #FF007D;
}

